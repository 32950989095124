<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item"><a :class="{'active': tab == '#recorded'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#recorded">Recorded Video</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#animated'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#animated">Animated Presentation</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#podcast'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#podcast">Podcast</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#smartbook'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#smartbook">SmartBook</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" v-if="tab == '#recorded'">
                    <list-recorded></list-recorded>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#animated'">
                    <list-animated></list-animated>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#podcast'">
                    <list-podcast></list-podcast>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#smartbook'">
                    <list-document></list-document>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ListAnimated from './Animated/ListAnimated.vue'
import ListDocument from './Document/ListDocument.vue'
import ListPodcast from './Podcast/ListPodcast.vue'
import ListRecorded from './Recorded/ListRecorded.vue'

export default {
    components:{
        ListAnimated,
        ListDocument,
        ListPodcast,
        ListRecorded,
    },
    mounted() {
        if (this.$route.hash) {
            this.tab = this.$route.hash
        }
    },
    data() {
        return{
            paramsId: this.$route.params.idCourse,
            tab: '#recorded'
        }
    },
}
</script>
