<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12"><h2 class="fw-400 font-lg d-block"><b>Podcast</b></h2></div>
            <div class="col-lg-8 col-xl-8 col-md-8 col-sm-8">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter" @keyup="searchTable()">
            </div>
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                <select class="form-control" v-model="cycle">
                    <option value="" selected>All Cycle</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                <router-link :to="'/admin/courses/'+paramsId+'/files/podcast/create'" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                </router-link>
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div class="col-12 text-center py-3" v-if="material.length">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <table class="table table-hover table-bordered" id="custom-table">
                                <thead>
                                    <tr>
                                        <th class="border-0 bg-current text-white p-3" scope="col" colspan="2">No</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">File</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Title</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Description</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Cycle</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Unit</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Created By</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Date</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Option</th>
                                    </tr>
                                </thead>
                                <draggable v-model="material" tag="tbody" handle=".handle" :disabled="!drag" @end="reOrder()">
                                    <tr v-for="(row, index) in material" :key="index">
                                        <td><a href="javascript:void(0)"><i class="fas fa-bars handle"></i></a></td>
                                        <td>{{ index+1 }}</td>
                                        <td>
                                            <img :src="row.thumbnail" width="50" class="rounded" alt="...">
                                        </td>
                                        <td>{{ row.title }}</td>
                                        <td>{{ row.description }}</td>
                                        <td>{{ row.cycle }}</td>
                                        <td>{{ row.unit_id ? row.unit_id.content : '' }}</td>
                                        <td>{{ row.created_by?.name || '-' }}</td>
                                        <td>{{ row.created_at | datetime}}</td>
                                        <td>
                                            <router-link :to="'/admin/courses/'+paramsId+'/files/podcast/detail/'+row.id"><i class="ti-bookmark font-xs text-grey-500 mr-4"></i></router-link>
                                            <router-link :to="'/admin/courses/'+paramsId+'/files/podcast/edit/'+row.id"><i class="ti-pencil font-xs text-grey-500 mr-4"></i></router-link>
                                            <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 text-center" v-if="!material.length">
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data don't exist</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import draggable from 'vuedraggable';
export default {
    name: 'ListAnimated',
    filters:{
        datetime: function (date) {
            return moment(date).calendar();
        }
    },
    components: {
        draggable
    },
    data(){
        return{
            media: "",
            paramsId: this.$route.params.idCourse,
            syllabus: [],
            cycle: '',
            drag: true,
            page: 1,
            tableFilter: '',
            material: [
                {
                    id: '',
                    thumbnail: '',
                    title: '',
                    description: '',
                    cycle: '',
                    created_at: '2021-10-05 01:00:00',
                    created_by: '',
                    unit_id: '',
                }
            ],
        }
    },
    created(){
        this.getMaterials()
        this.getSyllabus()
    },
    watch:{
        cycle:function(){
            this.getMaterials()
        }
    },
    methods:{
        async getMaterials(){
            await axios.get(`${process.env.VUE_APP_URL_API}/teacher/material?slug_course=${this.paramsId}&type=3&cycle=${this.cycle}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.material = res.data
            })
        },
        async getSyllabus(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus?slug=${this.paramsId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.syllabus = res.data
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteMaterial(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Succcess',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteMaterial(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/teacher/material`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.getMaterials()
            }).catch(() => {
                this.getMaterials()
            })
        },
        async reOrder() {
            this.drag = false
            var data ={
                materials: this.material,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/teacher/reorder`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Material',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.drag = true;
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Material',
                    text: 'Failed Reorder!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getMaterials();
                this.drag = true;
            })
        },
        searchTable(){
            var input, table, tr, td, i, j, txtValue, show;
            input = this.tableFilter.toLowerCase();
            table = document.getElementById("custom-table");
            tr = table.querySelectorAll('tbody tr')
            for (i = 0; i < tr.length; i++) {
                show = false
                td = tr[i].getElementsByTagName("td");
                if (td.length > 0) {
                    for(j = 0; j < td.length; j++) {
                        txtValue = td[j].textContent || td[j].innerText;
                        if (txtValue.toLowerCase().indexOf(input) > -1) {
                            show = true
                        }
                    }
                }
                tr[i].style.display = !show ? "none" : "";
            }

        },
    },
}
</script>